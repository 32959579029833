<template>
  <div class="content">
    <moreHeader :iconColor="1"></moreHeader>
    <div class="header"></div>
    <div class="text">
      <h1>关于沃赛</h1>
      <p>
        杭州沃赛科技有限公司成立于2014年，座落于杭州西湖区，毗邻浙江大学。公司创始股东们具备丰富的行业经验和资源，研发团队由一批互联网精英所组成，依托浙等重点高校和科研机构，拥有广阔的技术前景和人才优势。沃赛科技始终以客户需求和技术驱动应用创新为导向，专注于智慧城市业务应用领域的软件平台开发，利用AI和大数据等技术，长期为政府和IT集成客户提供解决方案和技术服务，全心致力于数据治理及应用开发与交付。
      </p>
    </div>
    <div class="map">
      <el-amap class="amap-box" :vid="'amap-vue'" :center="center" :zoom="zoom">
        <el-amap-marker :icon="iconImg"></el-amap-marker>
      </el-amap>
    </div>
  </div>
</template>

<script>
import moreHeader from "../../components/header";
export default {
  components: { moreHeader },
  name: "",
  data() {
    return {
      center: [120.100659, 30.300959], // 中心坐标
      zoom: 16, // 放大比例
      iconImg: require("@/assets/assets/mapicon.png"), // 图片路径
    };
  },

  methods: {},
};
</script>

<style scoped lang="scss">
.content {
  box-sizing: border-box;
  margin-top: -27px;
  .header {
    height: 480px;
    background: url("../../assets/assets/home.png") no-repeat;
    background-size: 100% 100%;
    padding-left: 10px;
  }
  .text {
    width: calc(100% - 20px);
    margin-left: 10px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px 10px;
    margin-top: 15px;
    > h1 {
      font-size: 20px;
      font-weight: normal;
      color: #333333;
    }
    > p {
      text-indent: 2em;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 300;
      line-height: 28px;
      color: #666666;
    }
  }
  .map {
    width: calc(100% - 20px);
    height: 200px;
    margin-left: 10px;
    background: #000;
  }
}
::v-deep .amap-logo {
  display: none;
  opacity: 0 !important;
}
.amap-copyright {
  opacity: 0;
}
</style>
